<template>
  <p class="error">{{ error }}</p>
  <p class="decode-result">
    Last result: <b>{{ result }}</b>
  </p>
  <div class="qrcode">
    <qrcode-stream
      @init="onInit"
      @decode="onDecode"
      :camera="camera"
      :track="paintBoundingBox"
      :paused="paused"
    ></qrcode-stream>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";

export default {
  name: "QRCode",

  data() {
    return {
      camera: "rear",
      paused: false,
      result: "",
      error: "",
    };
  },

  //   computed: {
  //     ...mapGetters({
  //       villes: "villes/villes",
  //     }),
  //   },

  methods: {
    onDecode(result) {
      this.result = result;
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    pauseCamera() {
      this.paused = true;
    },
    unPauseCamera() {
      this.paused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: $primary;
}
.error {
  font-weight: bold;
  color: red;
}
</style>
